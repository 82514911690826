<template>
  <div class="main-container">
    <div class="relative z-10 py-32">
      <p
        class="tracking-wider text-gray-900 font-bold text-xl text-center md:text-3xl md:text-left md:ml-20 mb-12"
      >
        TIM PENGAJAR
      </p>
      <div
        class="container px-8 mx-auto flex flex-wrap items-center justify-center gap-6"
      >
        <div
          class="w-72 bg-white text-left shadow rounded"
          v-for="(item, key) in teachers"
          :key="key"
        >
          <img
            class="mb-4 w-full mx-auto"
            :src="item.thumbnail_url_full"
            alt=""
          />
          <div class="p-4">
            <p class="font-bold">{{ item.name }}</p>
            <span class="text-gray-500 mb-4 block">{{ item.education }}</span>
            <p>{{ item.description }}</p>
          </div>
        </div>

        <!-- Rollback -->
        <div v-if="teachers.length === 0">
          <svg
            role="img"
            width="340"
            height="84"
            aria-labelledby="loading-aria"
            viewBox="0 0 340 84"
            preserveAspectRatio="none"
          >
            <title id="loading-aria">Loading...</title>
            <rect
              x="0"
              y="0"
              width="100%"
              height="100%"
              clip-path="url(#clip-path)"
              style="fill: url('#fill')"
            ></rect>
            <defs>
              <clipPath id="clip-path">
                <rect x="0" y="0" rx="3" ry="3" width="67" height="11" />
                <rect x="76" y="0" rx="3" ry="3" width="140" height="11" />
                <rect x="127" y="48" rx="3" ry="3" width="53" height="11" />
                <rect x="187" y="48" rx="3" ry="3" width="72" height="11" />
                <rect x="18" y="48" rx="3" ry="3" width="100" height="11" />
                <rect x="0" y="71" rx="3" ry="3" width="37" height="11" />
                <rect x="18" y="23" rx="3" ry="3" width="140" height="11" />
                <rect x="166" y="23" rx="3" ry="3" width="173" height="11" />
              </clipPath>
              <linearGradient id="fill">
                <stop offset="0.599964" stop-color="#d7d7d7" stop-opacity="1">
                  <animate
                    attributeName="offset"
                    values="-2; -2; 1"
                    keyTimes="0; 0.25; 1"
                    dur="2s"
                    repeatCount="indefinite"
                  ></animate>
                </stop>
                <stop offset="1.59996" stop-color="#ecebeb" stop-opacity="1">
                  <animate
                    attributeName="offset"
                    values="-1; -1; 2"
                    keyTimes="0; 0.25; 1"
                    dur="2s"
                    repeatCount="indefinite"
                  ></animate>
                </stop>
                <stop offset="2.59996" stop-color="#d7d7d7" stop-opacity="1">
                  <animate
                    attributeName="offset"
                    values="0; 0; 3"
                    keyTimes="0; 0.25; 1"
                    dur="2s"
                    repeatCount="indefinite"
                  ></animate>
                </stop>
              </linearGradient>
            </defs>
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Axios from "axios";
export default {
  data() {
    return {
      teachers: [],
    };
  },
  methods: {
    fetchTeacher() {
      Axios.get("api/teacher").then((response) => {
        this.teachers = response.data.teacher;
      });
    },
  },
  mounted() {
    this.fetchTeacher();
  },
  name: "TeacherList",
};
</script>

<style lang="scss" scoped>
.main-container {
  @apply pb-2 pt-10 p-2 z-10 relative bg-gradient-to-b from-gray-200 to-white;
}
</style>